.c-dropdown {
	position: relative;
}

.c-dropdown__menu {
	position: absolute;
	width: 100%;
	background-color: $color-white;
	padding: $ident--small $ident--semi-large;
	box-shadow: 0 0 0 0.1rem $color-grey--light;
	display: none;
	max-height: 24rem;
	overflow: auto;
	z-index: 9999;

	.open & {
		display: block;
	}
}

.c-dropdown__menu-item {
	position: relative;
	padding: $ident 0;
	padding-left: 1.6rem + $ident;
	font-size: 1.4rem;
	line-height: 1.6rem;
	color: $color-typo;
	@include text-overflow;
	transition: color 0.3s;
	cursor: pointer;

	+ .c-dropdown__menu-item {
		border-top: 0.1rem solid $color-grey--light;
	}

	&::after {
		content: '';
		position: absolute;
		top: $ident;
		left: 0;
		width: 1.6rem;
		height: 1.6rem;
		background: $color-grey url(/resources/svg/icon-check--white.svg) no-repeat center center;
		background-size: 60%;
		border-radius: 50%;
	}

	&:hover {
		color: $color-blue;
	}

	&--active::after {
		background-color: $color-blue;
	}
}

.c-dropdown__menu-divider {
	padding: $ident--small 0;
	font-size: 1.4rem;
	line-height: 1.6rem;
	color: $color-typo--light;
}
