.l-modal {
	display: none;

	&.is-open {
		display: block;
	}
}

.l-modal__overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0, 0.75);
	z-index: 99999;
	will-change: transform;

	[aria-hidden="false"] & {
		animation: modal-overlay-in 0.3s ease-out;
	}

	[aria-hidden="true"] & {
		animation: modal-overlay-out 0.3s ease-out;
	}

	@media all and (max-width: $screen-s) {
		align-items: flex-start;
	}
}

.l-modal__container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 56rem;
	max-height: 100vh;
	border-radius: $border-radius;
	background-color: $color-white;
	overflow-y: auto;
	will-change: transform;

	[aria-hidden="false"] & {
		animation: modal-container-in 0.3s ease-out;
	}

	[aria-hidden="true"] & {
		animation: modal-container-out 0.3s ease-out;
	}

	@media all and (max-width: $screen-s) {
		max-width: 100%;
		height: 100vh;
	}
}

// .l-modal__header {
// 	flex-shrink: 0;
// 	flex-grow: 0;
// 	display: flex;
// 	align-items: center;
// 	padding: $ident--extra-large $ident--extra-large 0;

// 	@media all and (max-width: $screen-s) {
// 		padding: $ident--large;
// 	}
// }

// .l-modal__header-logo {
// 	flex-shrink: 0;
// 	flex-grow: 0;
// 	display: none;
// 	width: 16rem;
// 	height: 3.4rem;
// 	background: url(/public/img/frontwebsite/logo-shiptify.png) no-repeat left center;
// 	background-size: contain;

// 	@media all and (max-width: $screen-s) {
// 		display: block;
// 	}
// }

// .l-modal__header-close {
// 	flex-shrink: 0;
// 	flex-grow: 0;
// 	position: relative;
// 	width: 2rem;
// 	height: 2rem;
// 	margin-left: auto;
// 	cursor: pointer;
// 	opacity: 0.666;
// 	transition: opacity 0.3s;

// 	&:hover {
// 		opacity: 1;
// 	}

// 	&::before,
// 	&::after {
// 		content: '';
// 		position: absolute;
// 		width: 100%;
// 		height: 0.2rem;
// 		top: 50%;
// 		left: 50%;
// 		font-size: 0;
// 		margin-top: -0.1rem;
// 		margin-left: -50%;
// 		background-color: $color-typo;
// 		border-radius: 0.1rem;
// 		transition: all 0.3s;
// 	}

// 	&::before {
// 		transform: rotate(45deg);
// 	}

// 	&::after {
// 		transform: rotate(-45deg);
// 	}
// }

.l-modal__content {
	flex-shrink: 1;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: $ident--extra-large;

	@media all and (max-width: $screen-s) {
		padding: $ident--extra-large $ident--large;
	}
}

.l-modal__content-titles {
	margin-bottom: 4rem;
}

.l-modal__content-title {
	@include typo-title-h2;
	color: $color-typo;
}

.l-modal__content-subtitle {
	font-size: 1.6rem;
	color: $color-typo;
	margin-top: $ident;
}

// animations

@keyframes modal-overlay-in {

	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes modal-overlay-out {

	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes modal-container-in {

	from {
		transform: translateY(15%);
	}

	to {
		transform: translateY(0);
	}
}

@keyframes modal-container-out {

	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(-10%);
	}
}