// typo

$typo-family:				'Inter', sans-serif;
$typo-family--alt:			$typo-family;

$typo-regular:				400;
$typo-medium:				600;
$typo-bold:					700;
$typo-alt-weight:			$typo-bold;

// colors

$color-white:				#ffffff;
$color-typo:				#132348;
$color-typo--light:			#98acba;
$color-grey:				#d5dae3;
$color-grey--light:			#e5eaf3;
$color-grey--lightest:		#f3f4f8;
$color-blue:				#02a7df;
$color-blue--dark:			#08193d;
$color-red:					#ff546d;
$color-green:				#16e3a2;
$color-orange:				#fe9301;
$color-yellow:				#fed501;
$color-purple:				#9f02e2;
$color-pink:				#eb17ab;
$color-turquoise:			#68c9d5;
$color-cta:					$color-green;

$gradient:					linear-gradient(to right, #1d78ba 0%, #65c9d4 100%);
$gradient--option:			linear-gradient(to right top, #252567 0%, #145aaf 50%, #57c1cf 100%);

// breakpoints

$screen-xl:					1440px;
$screen-l:					1200px;
$screen-m:					1070px;
$screen-s:					768px;

// sizes

$container:					110rem;
$container--medium:			90rem;
$container--small:			70rem;

$ident--small:				0.5rem;
$ident:						1rem;
$ident--semi-large:			1.5rem;
$ident--large:				2rem;
$ident--extra-large:		3rem;

$border-radius:				0.4rem;
$border-radius--large:		0.8rem;
$border-radius:				0;
$border-radius--large:		0;

// ui

$header-height:             7.5rem;