.h-hidden {
	display: none !important;
}

.h-hidden-small {

	@media all and (max-width: $screen-s) {
		display: none !important;
	}
}

.h-hidden-large {

	@media all and (min-width: $screen-s + 1px) {
		display: none !important;
	}
}

// typo

.h-typo-nowrap {
	white-space: nowrap;

	@media all and (max-width: $screen-l) {
		white-space: inherit;
	}
}

.h-typo-bold {
	font-weight: $typo-bold;
}

.h-typo-center {
	text-align: center;
}

.h-typo-uppercase {
	text-transform: uppercase;
}

.h-typo-centered {
	text-align: center;
}

.h-typo-primary {
	color: $color-typo;
}

.h-typo-secondary {
	color: $color-typo--light;
}

.h-typo-secondary-o {
	opacity: 0.75;
}

.h-typo-secondary-oo {
	opacity: 0.35;
}

.h-typo-cta {
	color: $color-cta;
}

.h-typo-blue {
	color: $color-blue;
}

@mixin typo-eyebrow {
	font-family: $typo-family--alt;
	font-size: 1.4rem;
	font-weight: $typo-alt-weight;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.h-typo-eyebrow {
	@include typo-eyebrow;
}

@mixin typo-title {
	line-height: 1.2;
	font-weight: $typo-bold;
}

@mixin typo-title-h1 {
	font-size: 4.8rem;
	@include typo-title;

	@media all and (max-width: $screen-s) {
		font-size: 3.6rem;
	}
}

.h-typo-title-h1 {
	@include typo-title-h1;
}

@mixin typo-title-h2 {
	font-size: 3.2rem;
	@include typo-title;
}

.h-typo-title-h2 {
	@include typo-title-h2;
}

@mixin typo-title-h3 {
	font-size: 2.2rem;
	@include typo-title;
}

.h-typo-title-h3 {
	@include typo-title-h3;
}
