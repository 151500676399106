.c-cta {
	padding: $ident--extra-large $ident--large 4rem;
	color: $color-white;
	text-align: center;
	background: $gradient--option;
	border-radius: $border-radius;
	@include box-shadow--small;
}

.c-cta__typo {
	font-size: 1.6rem;
	color: rgba($color-white, 0.75);
	margin-bottom: $ident--large;
}

.c-cta__logo {
	display: block;
	width: 15rem;
	height: 4rem;
	background: url(/resources/logo-large.png) no-repeat center center;
	background-size: contain;
	margin: 0 auto 4rem;
}