.c-header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 101;

	display: flex;
	align-items: center;
	height: $header-height;
	padding: $ident--large;
	background-color: $color-white;
	@include box-shadow;

	&--details {
		position: fixed;
		top: -($header-height + $ident);
		left: 0;
		right: 0;
		z-index: 102;

		transition: top 0.3s;

		@media all and (max-width: $screen-m) {
			display: none;
		}
	}

	&--details-appeared {
		top: 0;
	}
}

// logo

.c-header__logo {
	flex-shrink: 0;
	flex-grow: 0;
	width: 16rem;
}

.c-header__logo-img {
	display: block;
	width: 100%;
}

// nav

.c-header__nav {
	flex-shrink: 0;
	flex-grow: 0;
	display: flex;
	align-items: center;
	margin-left: auto;

	@media all and (max-width: $screen-m) {
		display: none;
	}
}

.c-header__nav-item {
	margin-left: $ident--large;
}

// company

.c-header__company {
	flex-shrink: 1;
	flex-grow: 1;
	display: flex;
	align-items: center;
	overflow: hidden;
	margin-left: -$ident;
}

.c-header__company-avatar {
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: $ident--semi-large;

	&--closed {
		filter: grayscale(100%);
		opacity: 0.33;
	}
}

.c-header__company-typo {
	flex-shrink: 1;
	flex-grow: 1;
	overflow: hidden;
}

.c-header__company-title {
	display: block;
	font-size: 1.8rem;
	font-weight: $typo-medium;
	@include text-overflow;

	&--closed {
		color: $color-typo--light;
	}
}

.c-header__company-p {
	display: block;
	@include text-overflow;
	color: $color-typo--light;
}