.c-avatar {
	width: 5.5rem;
	height: 5.5rem;
	border-radius: $border-radius;
	background-size: cover;

	&--large {
		width: 8rem;
		height: 8rem;
	}
}
