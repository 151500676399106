html, body {
	height: 100%;
}

html {
	box-sizing: border-box;
	font-family: $typo-family;
	font-weight: $typo-regular;
	font-size: 10px;
	color: $color-typo;
	-webkit-font-smoothing: antialiased;
}

body {
	font-size: 1.5rem;
	line-height: 1.4;
}

*, *::before, *::after {
	box-sizing: inherit;
}

a {
	color: $color-cta;
}
