.c-title {
	margin-bottom: $ident--large;
}

.c-title__typo {
	color: $color-typo--light;
	line-height: 3rem;
}

.c-title__action {
	display: inline-block;
	padding: 0 $ident--semi-large;
	line-height: 3rem;
	font-size: 1.4rem;
	color: $color-blue;
	text-decoration: none;
	box-shadow: inset 0 0 0 0.1rem $color-blue;
	border-radius: 1.5rem;
	opacity: 0.75;
	transition: opacity 0.3s;

	@include attention {
		color: $color-blue;
		text-decoration: none;
		opacity: 1;
	}

	.c-title__typo + & {
		margin-left: $ident--small;
	}
}