.l-grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0 (-$ident);
}

.l-grid__column {
	flex: 0 0 100%;
	max-width: 100%;
	padding: 0 $ident;

	&--50 {
		flex-basis: 50%;
		max-width: 50%;
	}

	&--33 {
		flex-basis: 33.33%;
		max-width: 33.33%;
	}

	&--25 {
		flex-basis: 25%;
		max-width: 25%;
	}
}