.m-job__field {
	margin-bottom: $ident--large;
}

// circles

.m-job__info {
	display: flex;
	overflow: hidden;
	margin: 0 (-$ident) $ident--extra-large;
}

.m-job__info-item {
	flex-shrink: 1;
	flex-grow: 1;
	position: relative;
	width: 100%;
	padding: 0 $ident;

	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 2.5rem;
		width: calc(50% - 2.5rem);
		height: 0.2rem;
		background-color: $color-blue;
		margin-top: -0.1rem;
	}

	&::after {
		left: auto;
		right: 0;
	}

	&:first-child::before,
	&:last-child::after {
		display: none;
	}
}

.m-job__info-item-circle {
	width: 5rem;
	line-height: 5rem;
	text-align: center;
	font-size: 2rem;
	color: $color-blue;
	background-color: $color-white;
	box-shadow: inset 0 0 0 0.2rem $color-blue;
	border-radius: 50%;
	margin: 0 auto $ident;
}

.m-job__info-item-typo {
	max-width: 14rem;
	color: $color-blue;
	font-size: 1.2rem;
	text-align: center;
	margin: 0 auto;
}

// alert

.m-job__info-alert {
	margin-bottom: $ident--extra-large;
}

// buttons

.m-job__buttons {
	display: flex;
}

.m-job__buttons-item {
	flex-shrink: 1;
	flex-grow: 1;
	width: 0;
	@include text-overflow;

	.m-job__buttons-item + & {
		margin-left: $ident;
	}
}

// thank you

.m-job__thanks {
	max-width: 34rem;
	text-align: center;
	margin: $ident--extra-large auto;
}

.m-job__thanks-image {
	height: 6rem;
	width: 6rem;
	margin: 0 auto $ident;
}

.m-job__thanks-title {
	@include typo-title-h3;
	color: $color-typo;
	margin-bottom: $ident;
}

.m-job__thanks-text {
	color: $color-typo--light;
}

.m-job__thanks-button {
	margin-top: $ident--large;

	a {
		color: #ffffff !important;
	}
}

// company

.m-job__company {
	display: flex;
	align-items: center;
	margin-bottom: $ident--extra-large;
}

.m-job__company-avatar {
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: $ident--large;

	@media all and (max-width: $screen-s) {
		width: 6rem;
		height: 6rem;
		margin-right: $ident--semi-large;
	}

	&--closed {
		filter: grayscale(100%);
		opacity: 0.33;
	}
}

.m-job__company-typo {
	flex-shrink: 1;
	flex-grow: 1;
	width: 0;
	color: $color-typo;
	line-height: 1.6;

	&--closed {
		color: $color-typo--light;
	}
}

.m-job__company-title {
	@include typo-title-h3;
	@include text-overflow;
}

.m-job__company-text {
	font-size: 1.4rem;
	@include text-overflow;
	opacity: 0.9;
}