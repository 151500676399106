.c-position {
	display: block;
	padding: $ident--extra-large;
	color: $color-typo;
	text-decoration: none;
	overflow: hidden;
	background-color: $color-white;
	border-radius: $border-radius;
	@include box-shadow--small;

	@include attention {
		color: $color-typo;
		text-decoration: none;
	}

	.c-position + & {
		margin-top: $ident--large;

		@media all and (max-width: $screen-s) {
			margin-top: $ident;
		}
	}
}

.c-position__header {
	display: flex;
	align-items: center;
}

.c-position__header-avatar {
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: $ident--large;

	@media all and (max-width: $screen-s) {
		width: 6rem;
		height: 6rem;
		margin-right: $ident--semi-large;
	}

	&--closed {
		filter: grayscale(100%);
		opacity: 0.33;
	}
}

.c-position__header-typo {
	flex-shrink: 1;
	flex-grow: 1;
	width: 0;
	color: $color-typo;
	line-height: 1.6;

	&--closed {
		color: $color-typo--light;
	}
}

.c-position__header-eyebrow {
	font-size: 1.4rem;
	@include text-overflow;
	opacity: 0.9;
}

.c-position__header-title {
	@include typo-title-h3;
	margin: $ident--small 0;
}

.c-position__header-title-label {
	display: inline-block;
	padding: 0 $ident--small;
	font-size: 1.2rem;
	line-height: 2rem;
	font-weight: $typo-medium;
	color: $color-white;
	background-color: $color-typo--light;
	margin-left: $ident--small;
}

.c-position__header-meta {
	font-size: 1.2rem;
	@include text-overflow;
	opacity: 0.9;
}

.c-position__header-meta-item + .c-position__header-meta-item {
	margin-left: $ident;
}

.c-position__header-meta-item-icon {
	display: inline-block;
	vertical-align: middle;
	width: 1rem;
	opacity: 0.5;
}

.c-position__header-button {
	flex-shrink: 0;
	flex-grow: 0;
	align-self: flex-start;
	margin-left: $ident--large;

	@media all and (max-width: $screen-s) {
		display: none;
	}
}

.c-position__divider {
	margin-top: 2 * $ident--extra-large;
}

.c-position__details-title {
	font-size: 1.8rem;
	font-weight: $typo-medium;
	padding-bottom: $ident;
	border-bottom: 0.1rem solid $color-grey--lightest;
	margin-bottom: $ident--semi-large;

	&::before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: $ident;
		height: 0.2rem;
		background-color: $color-blue;
		margin-right: $ident;
	}
}

.c-position__details-typo {
	color: $color-typo;
	font-size: 1.4rem;
	line-height: 1.8;
	margin-bottom: $ident--extra-large;
}

.c-position__details-typo p {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: $ident;
}
