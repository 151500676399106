.c-field {
	position: relative;
}

.c-field__input {
	position: relative;
	display: block;
	width: 100%;
	font-family: $typo-family;
	color: $color-typo;
	font-size: 1.4rem;
	line-height: 2rem;
	background-color: transparent;
	padding: 1rem 1.5rem;
	border: 0.2rem solid $color-grey--light;
	border-radius: $border-radius;
	transition: border-color 0.3s;
	z-index: 2;

	@include attention {
		outline: none;
	}

	&:focus {
		border-color: $color-blue;
	}

	&--error {
		border-color: $color-red;
	}

	&--textarea {
		height: 10rem;
		resize: none;
	}

	&::placeholder {
		color: $color-typo--light;
	}

	&--clear,
	&--password {
		padding-right: 4rem;
	}
}

.c-field__label {
	position: absolute;
	top: 0.2rem;
	left: 1rem;
	max-width: calc(100% - 2rem);
	padding: 0 0.5rem;
	color: $color-typo--light;
	font-size: 1.4rem;
	line-height: 4rem;
	background-color: $color-white;
	@include text-overflow;
	transition: top 0.1s, font-size 0.1s, line-height 0.1s, color 0.3s;
	z-index: 1;

	.c-field__input--filled ~ &,
	.c-field__input:focus ~ & {
		top: -0.4rem;
		font-size: 1.1rem;
		line-height: 1.1rem;
		z-index: 3;
	}

	.c-field__input:focus ~ & {
		color: $color-blue;
	}

	.c-field__input--error ~ & {
		color: $color-red;
	}
}
