.c-alert {
	padding: $ident--large;
	font-size: 1.4rem;
	color: $color-typo--light;
	background-color: $color-grey--lightest;
	border: 0.1rem solid $color-typo--light;
	border-radius: $border-radius;

	&--red {
		color: $color-red;
		background-color: rgba($color-red, 0.1);
		border-color: $color-red;
	}

	&--blue {
		color: $color-blue;
		background-color: rgba($color-blue, 0.1);
		border-color: $color-blue;
	}
}

.c-alert__line {
	color: inherit;

	.c-alert__line + & {
		margin-top: $ident--small;
	}
}