.l-layout {
	padding: 10rem 0 3rem;
	background-color: $color-grey--lightest;
}

.l-layout__container {
	display: flex;
	width: 100%;
	max-width: $container;
	padding: 0 $ident--large;
	margin: 0 auto;

	@media all and (max-width: $screen-l) {
		max-width: $container--medium;
	}

	@media all and (max-width: $screen-m) {
		max-width: $container--small;
	}

	@media all and (max-width: $screen-s) {
		flex-direction: column;
	}
}

.l-layout__container-left {
	flex-shrink: 0;
	flex-grow: 0;
	width: 26rem;
	margin-right: $ident--extra-large;

	@media all and (max-width: $screen-m) {
		display: none;
	}
}

.l-layout__container-left-inner {
	position: -webkit-sticky;
	position: sticky;
	top: $header-height + $ident--extra-large;
	margin-top: 3rem + $ident--large;
}

.l-layout__container-right {
	flex-shrink: 1;
	flex-grow: 1;
	width: 0;

	@media all and (max-width: $screen-s) {
		width: 100%;
	}
}
