.c-dropzone {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10rem;
	border: 0.2rem dashed $color-grey--light;
	cursor: pointer;
}

.c-dropzone__content {
	font-size: 1.4rem;
	color: $color-typo--light;
	text-align: center;
}

.c-dropzone__content-preview {
	display: block;
	color: $color-blue;
	margin-top: $ident;
}