.c-socials {
    color: #ffffff;
    background-color: $color-blue;
    border-radius: $border-radius;
    transition: opacity 0.3s;

    @include attention {
        opacity: 0.75;
    }

    .at-share-btn {
        display: none !important;
        padding: 10px 20px !important;
        margin: 0 !important;
        background-color: transparent !important;

        @include attention {
            transform: translateY(0) !important;
            outline: none !important;
        }
    }

    .at-svc-linkedin {
        display: flex !important;
        align-items: center !important;
    }

    .at4-visually-hidden {
        display: none !important;
    }

    .at-share-btn::before {
        content: 'Partager cette offre sur';
        font-size: 14px;
        font-weight: $typo-medium;
    }

    .at-share-btn .at-icon-wrapper {
        position: relative !important;
        top: -2px;
        width: 20px !important;
        height: 20px !important;
        line-height: 20px !important;
        margin-left: 5px !important;
    }

    .at-share-btn .at-icon-wrapper svg {
        display: block !important;
        width: 100% !important;
        height: 100% !important;
        fill: $color-white !important;
        margin: 0 !important;
    }
}