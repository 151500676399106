@mixin c-button--typo {
	padding: 1.5*$ident 0;
	background-color: transparent;
}

.c-button {
	display: inline-block;
	padding: 1.5*$ident 2.5*$ident;
	font-size: 1.6rem;
	font-weight: $typo-medium;
	text-decoration: none;
	text-align: center;
	color: $color-white;
	background-color: $color-cta;
	border: none;
	transition: opacity 0.3s;
	cursor: pointer;

	@include attention {
		opacity: 0.75;
		outline: none;
	}

	svg {
		display: inline-block;
		vertical-align: top;
		width: 1.6rem;
		height: 1.6rem;
		fill: $color-white;
		margin-left: 1rem;
	}

	// sizes

	&--small {
		font-size: 1.4rem;
		padding: $ident 2*$ident;
	}

	&--typo {
		@include c-button--typo;
		color: $color-cta;

		svg {
			fill: $color-cta;
		}
	}

	&--white {
		color: $color-blue;
		background-color: $color-white;
	}

	&--blue {
		background-color: $color-blue;
	}

	&--outline-blue {
		color: $color-blue;
		background-color: transparent;
		box-shadow: inset 0 0 0 0.1rem $color-blue;
	}

	&--outline-white {
		color: $color-white;
		background-color: transparent;
		box-shadow: inset 0 0 0 0.1rem $color-white;
	}

	&--grey {
		color: $color-typo;
		background-color: $color-grey;
	}

	&--typo-blue {
		@include c-button--typo;
		color: $color-blue;

		svg {
			fill: $color-blue;
		}
	}

	&--disabled,
	&--disabled:hover {
		color: $color-white;
		background-color: $color-grey;
		opacity: 0.75;
		cursor: default;
		pointer-events: none;
	}
}