@mixin attention {

	&:hover,
	&:active,
	&:focus {
		@content;
	}
}

@mixin text-overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin box-shadow {
	box-shadow: 0 0 2rem 0 rgba($color-typo, 0.1);
}

@mixin box-shadow--large {
	box-shadow: 0 0 4rem 0 rgba($color-typo, 0.2);
}

@mixin box-shadow--small {
	box-shadow: 0 0 0.5rem 0 rgba($color-typo, 0.06);
}