.c-filters {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	padding: 0 $ident--large;
	margin-bottom: $ident--large;
	z-index: 1;
	transition: background-color 0.3s, box-shadow 0.3s;

	&--scrolled {
		background-color: $color-white;
		@include box-shadow;
	}

	@media all and (max-width: $screen-s) {
		padding: 0 $ident--semi-large;
	}
}

.c-filters__items {
	display: flex;
	width: 100%;
	max-width: $container - 2 * $ident--large;
	background-color: $color-white;
	border-radius: $border-radius;
	@include box-shadow--small;
	margin: 0 auto;

	.c-filters--scrolled & {
		box-shadow: none;
	}

	@media all and (max-width: $screen-l) {
		max-width: $container--medium - 2 * $ident--large;
	}

	@media all and (max-width: $screen-m) {
		max-width: $container--small - 2 * $ident--large;
	}
}

.c-filters__item {
	flex-shrink: 1;
	flex-grow: 1;
	width: 0;
	padding: $ident 0;

	+ .c-filters__item {
		border-left: 0.1rem solid $color-grey--light;
	}
}

// dropdown

.c-filters__dropdown {
	position: relative;
}

.c-filters__dropdown-input {
	position: relative;
	display: block;
	width: 100%;
	height: 4rem;
	font-family: $typo-family;
	color: $color-typo;
	font-size: 1.4rem;
	line-height: 4rem - 2 * $ident;
	@include text-overflow;
	background-color: transparent;
	padding: $ident $ident--semi-large;
	padding-right: 4rem;
	z-index: 2;
	cursor: pointer;
}

.c-filters__dropdown-label {
	position: absolute;
	top: 0;
	left: $ident--semi-large;
	color: $color-typo--light;
	font-size: 1.4rem;
	line-height: 4rem;
	text-align: left;
	@include text-overflow;
	transition: top 0.1s, font-size 0.1s, line-height 0.1s, color 0.3s;
	z-index: 1;

	.c-filters__dropdown-input--filled ~ &,
	.c-filters__dropdown-input[aria-expanded="true"] ~ & {
		top: -0.4rem;
		font-size: 1.1rem;
		line-height: 1.1rem;
		z-index: 3;
	}

	.c-filters__dropdown-input[aria-expanded="true"] ~ & {
		color: $color-blue;
	}
}

.c-filters__dropdown-clear {
	display: none;
	position: absolute;
	top: 0.5rem;
	right: 1rem;
	padding: 0;
	width: 1.6rem;
	margin: 0.7rem;
	text-align: center;
	font-size: 1.4rem;
	opacity: 0.4;
	cursor: pointer;
	transition: opacity 0.3s;
	z-index: 3;

	&:hover {
		opacity: 0.6;
	}

	.c-filters__dropdown-input--filled ~ & {
		display: block;
	}
}

.c-filters__dropdown-menu {
	margin-top: 1rem;
}