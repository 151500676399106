.c-footer {
	padding: 6rem 0;
	background-color: $color-white;
	border-top: 0.1rem solid $color-grey--light;

	&--small {
		padding: 4rem 0;
	}
}

.c-footer__container {
	display: flex;
	width: 100%;
	max-width: $container;
	overflow: hidden;
	padding: 0 $ident--large;
	margin: 0 auto;

	@media all and (max-width: $screen-l) {
		max-width: $container--medium;
	}

	@media all and (max-width: $screen-m) {
		flex-wrap: wrap;
		max-width: $container--small;
	}
}

.c-footer__column {
	flex-shrink: 1;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	width: 0;

	@media all and (max-width: $screen-s) {
		width: 100%;
	}

	&--company {
		margin-left: 3rem;

		@media all and (max-width: $screen-s) {
			display: none;
		}
	}

	&--community {
		margin-left: 3rem;

		@media all and (max-width: $screen-s) {
			display: none;
		}
	}

	&--contact {
		margin-left: 3rem;

		@media all and (max-width: $screen-s) {
			margin-left: 0;
			margin-top: 3rem;
		}
	}

	&--subscribe {
		flex-shrink: 0;
		flex-grow: 0;
		width: 22rem;
		margin-left: 3rem;

		@media all and (max-width: $screen-m) {
			flex-direction: row;
			width: 100%;
			margin-left: 0;
			margin-top: 4rem;
			padding-top: 4rem;
			border-top: 0.1rem solid rgba($color-white, 0.1);
		}

		@media all and (max-width: $screen-s) {
			flex-direction: column;
			text-align: center;
		}
	}
}

//

.c-footer__meta {
	margin-top: auto;

	@media all and (max-width: $screen-m) {
		margin-top: 0;
		margin-left: auto;
	}

	@media all and (max-width: $screen-s) {
		margin: 4rem auto 0;
	}
}

//

.c-footer__copyright {
	font-size: 1.4rem;
	color: $color-typo--light;

	&--centered {
		text-align: center;
	}
}

//

.c-footer__nav {

	+ .c-footer__nav {
		margin-top: auto;
	}
}

.c-footer__nav-label {
	@include typo-eyebrow;
	line-height: 1.4;
	margin-bottom: $ident--large;

	@media all and (max-width: $screen-s) {
		text-align: center;
	}
}

.c-footer__nav-link {
	display: block;
	width: fit-content;
	font-size: 1.4rem;
	line-height: 1.2;
	color: $color-typo;
	text-decoration: none;
	margin-top: $ident;
	opacity: 0.9;
	transition: opacity 0.3s;

	@include attention {
		text-decoration: none;
		opacity: 1;
	}

	@media all and (max-width: $screen-s) {
		margin-left: auto;
		margin-right: auto;
	}
}

.c-footer__nav-p {
	display: block;
	width: fit-content;
	font-size: 1.4rem;
	line-height: 1.2;
	color: $color-typo--light;

	&--indent {
		margin-top: $ident;
	}

	&--nowrap {
		white-space: nowrap;
	}

	@media all and (max-width: $screen-s) {
		margin-left: auto;
		margin-right: auto;
	}
}

//

.c-footer__subscribe {
	margin-bottom: $ident--large;

	@media all and (max-width: $screen-m) {
		max-width: 22rem;
	}

	@media all and (max-width: $screen-s) {
		width: 100%;
		max-width: 36rem;
		margin: 0 auto;
	}
}

.c-footer__subscribe-label {
	font-size: 1.8rem;
	font-weight: $typo-medium;
	line-height: 1.2;
	margin-bottom: $ident--large;
}

.c-footer__subscribe-form {
	display: flex;
	width: 100%;
	overflow: hidden;
}

.c-footer__subscribe-input {
	flex-shrink: 1;
	flex-grow: 1;
	width: 100%;
	padding-left: 1.5rem;
	padding-right: 5.5rem;
	height: 4rem;
	line-height: 4rem;
	border: none;
	font-family: $typo-family;
	font-size: 1.6rem;
	color: $color-typo;
	background-color: $color-grey--light;

	&::placeholder {
		color: $color-typo--light;
	}

	@include attention {
		outline: none;
	}
}

.c-footer__subscribe-button {
	flex-shrink: 0;
	flex-grow: 0;
	width: 4rem;
	height: 4rem;
	padding: 1rem;
	background-color: $color-cta;
	border: none;
	outline: none;
	margin-left: -4*$ident;
	cursor: pointer;

	svg {
		display: block;
		width: 100%;
		fill: $color-white;
	}
}

//

.c-footer__lang {
	display: block;
	width: fit-content;
	font-size: 1.4rem;
	line-height: 1.4rem;
	color: rgba($color-typo, 0.75);
	white-space: nowrap;
	text-decoration: none;
	margin-bottom: $ident--large;
	transition: color 0.3s;

	@include attention {
		text-decoration: none;
		color: $color-typo;
	}

	@media all and (max-width: $screen-s) {
		margin-left: auto;
		margin-right: auto;
	}
}
